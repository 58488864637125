import React from 'react';
import Layout from '../layouts/layout'

function Terms() {
    return (
        <div>
            <Layout headerClass="bg-black h-[122px] relative">
                <div className='flex justify-center'>

                    <div className="container info-content py-20">



                    What Are Cookies
As is common practice with almost all professional websites, this site uses cookies. These are tiny files that are downloaded to your computer to improve your experience. This page describes what information they gather, how we use them and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may negatively impact or 'break' certain elements of the sites functionality.

How We Use Cookies
We use cookies for a variety of reasons detailed below. Unfortunately, in most cases there are no industry standard options for disabling cookies without completely or partially disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.

Disabling Cookies
You can prevent the setting of cookies by adjusting the settings on your browser (see the browser help pages linked below for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in disabling certain functionality and features of the this site.

Chrome: https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop

Firefox: https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox

Safari: https://support.apple.com/en-ca/guide/safari/sfri11471/mac

The Cookies We Set
Account related cookies
If you create an account with us then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.

Login related cookies
We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.

Email newsletters related cookies
This site offers newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users.

Orders processing related cookies
This site offers e-commerce or payment facilities and some cookies are essential to ensure that your order is remembered between pages so that we can process it properly.

Surveys related cookies
From time to time we offer user surveys and questionnaires to provide you with interesting insights, helpful tools, or to understand our user base more accurately. These surveys may use cookies to remember who has already taken part in a survey or to provide you with accurate results after you change pages.

Forms related cookies
When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.

Site preferences cookies
In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.

Third Party Cookies
In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.

This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.
For more information on Google Analytics cookies, see the official Google Analytics page.

Third party analytics are used to track and measure usage of this site so that we can continue to produce engaging content. These cookies may track things such as how long you spend on the site or pages you visit which helps us to understand how we can improve the site for you.
From time to time we test new features and make subtle changes to the way that the site is delivered. When we are still testing new features these cookies may be used to ensure that you receive a consistent experience whilst on the site whilst ensuring we understand which optimizations our users appreciate the most.
As we provide a paid service it's important for us to understand statistics about how many of the visitors to our site actually make a purchase and as such this is the kind of data that these cookies will track. This is important to you as it means that we can accurately make business predictions that allow us to monitor our advertising and service costs to ensure the best possible price.
The Google AdSense service we use to serve advertising uses a DoubleClick cookie to serve more relevant ads across the web and limit the number of times that a given ad is shown to you.
For more information on Google AdSense see the official Google AdSense privacy FAQ.

We also use social media buttons and/or plugins on this site that allow you to connect with your social network in various ways. For these to work the following social media sites including; Facebook, Instagram, Youtube, LinkedIn, Twitter, and Pinterest, will set cookies through our site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.
More Information
If you are still looking for more information please contact our Support team below:





                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default Terms;